import { combineReducers } from 'redux';
import loadingState from './app/loadingState/reducer';
import emailPreferencesState from './api/EmailPreferences/reducer';
import getAppLogoHeaderState from './api/logo/reducer';
import awsSecretsState from './api/awsSecrets/reducer';
import notificationState from './app/notificationState/reducer';

// We map all our reducers on to one central store object using combineReducers
// The key name we use here will be store.keyName
export const makeRootReducer = (asyncReducers) => combineReducers({
  emailPreferencesState,
  getAppLogoHeaderState,
  awsSecretsState,
  loadingState,
  notificationState,
  ...asyncReducers,
});

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer; // eslint-disable-line
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
