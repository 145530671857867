import Api from '../index';
import { API_BASE_PATH } from '../constants';

export const getAWSSecrets = async () => {
  try {
    const api = new Api();
    const baseUrl = `${API_BASE_PATH}/secrets`;
    const secretResponse = await api.getSecret(baseUrl);
    if (secretResponse.error) {
      throw secretResponse.error;
    }
    return secretResponse;
  } catch (error) {
    console.log('Error - getAWSSecrets', error);
    throw error;
  }
};

export default getAWSSecrets;
