import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import { triggerSaveEmailPreferencesRequest } from '../../../redux/api/EmailPreferences/actions';
import EmailPreferencesTemplate from './emailPreferencesTemplate';
import NotificationBanner from '@uicl/ui-core/dist/NotificationBanner';
import { deleteNotification } from '../../../redux/app/notificationState/actions';

const EmailPreferences = () => {
  const [emailPreferenceOption, setEmailPreferenceOption] = useState(true);
  const isValidEmailPreferenceOption = isString(emailPreferenceOption);
  const dispatch = useDispatch();
  const awsSecretState = useSelector((state) => state.awsSecretsState.successPostingAwsSecrets);
  const { notifications } = useSelector((state) => state.notificationState);
  const emailPreferencesOptions =  useSelector((state) => state.emailPreferencesState.successGettingEmailPreferences);
  const isOnlyOneEmailOptionAvailable = emailPreferencesOptions && emailPreferencesOptions.length === 1;

  const loadingState = useSelector((state) => state.loadingState);

  const isgetAppLogoHeaderLoading = loadingState.loading
    && (loadingState.loading.has('getAppLogoHeaderLoading') || loadingState.loading.has('awsSecretsResponseLoading'));
  const isSaveEmailPreferencesLoading = loadingState.loading && loadingState.loading.has('saveEmailPreferencesLoading');

  const {
    p, ri, vo, API_PASSWORD
  } = awsSecretState;

  const secretErrorResponse = isEmpty(API_PASSWORD) && 'An error occured';

  const onSave = () => {
    if (isOnlyOneEmailOptionAvailable) {
      const {optOutLevelCode} = emailPreferencesOptions[0];
      dispatch(triggerSaveEmailPreferencesRequest({ ri, emailPreferenceOption: optOutLevelCode, API_PASSWORD }));
    } else if (ri && emailPreferenceOption) {
      dispatch(triggerSaveEmailPreferencesRequest({ ri, emailPreferenceOption, API_PASSWORD }));
    } else {
      console.log("Can't Save Preferences");
    }
  };

  const onClickOptions = (e) => {
    const { id } = e;
    setEmailPreferenceOption(id);
  };

  const isReadOnly = (vo && vo.toUpperCase() === 'Y') || false;

  return (
    <div>
      {notifications.has(ri) && (
      <NotificationBanner
        domID="api-notification"
        type={notifications.get(ri).type}
        text={notifications.get(ri).msg}
        onDismiss={() => dispatch(deleteNotification(ri))}
        autoDismiss
        timer={1800000}
      />
    )}
    {(secretErrorResponse && isEmpty(loadingState.loading)) && (
      <NotificationBanner
        domID="internal-error"
        type="negative"
        text={secretErrorResponse}
        autoDismiss
        timer={1800000}
      />
    )}
      {!isEmpty(emailPreferencesOptions) && isArray(emailPreferencesOptions) &&
      <EmailPreferencesTemplate
        notifications={notifications}
        ri={ri}
        secretErrorResponse={secretErrorResponse}
        p={p}
        onClickOptions={onClickOptions}
        onSave={onSave}
        emailPreferencesOptions={emailPreferencesOptions}
        isgetAppLogoHeaderLoading={isgetAppLogoHeaderLoading}
        isSaveEmailPreferencesLoading={isSaveEmailPreferencesLoading}
        isValidEmailPreferenceOption={isValidEmailPreferenceOption}
        isOnlyOneEmailOptionAvailable={isOnlyOneEmailOptionAvailable}
        isReadOnly={isReadOnly}
      />}
    </div>
  );

};

export default EmailPreferences;
