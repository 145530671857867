import { put, call, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { saveEmailPreferences, getEmailPreferences } from './network';
import {
  saveEmailPreferencesRequestSuccess,
  saveEmailPreferencesRequestError,
  getEmailPreferencesRequestSuccess,
  getEmailPreferencesRequestError,
} from './actions';
import { setLoadingState } from '../../app/loadingState/actions';
import {
  SAVE_EMAIL_PREFERENCES_REQUEST_TRIGGER,
  GET_EMAIL_PREFERENCES_REQUEST_TRIGGER
} from './constants';
import { addNotification } from '../../app/notificationState/actions';

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* saveEmailPreferencesPostGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());\
  yield put(
    setLoadingState({
      id: 'saveEmailPreferencesLoading',
      isLoading: true,
    }),
  );
  try {
    const saveEmailPreferencesResponse = yield call(
      saveEmailPreferences,
      action.payload,
    );

    const { details } = saveEmailPreferencesResponse;

    if (!isEmpty(details) && details.toLowerCase().includes('unsubscribed')) {
      yield put(
        addNotification({
          id: action.payload.ri,
          type: 'positive',
          msg: saveEmailPreferencesResponse.details
        }),
      );
      yield put(saveEmailPreferencesRequestSuccess(saveEmailPreferencesResponse));
    } else {
      yield put(
        addNotification({
          id: action.payload.ri,
          type: 'negative',
          msg: saveEmailPreferencesResponse.details
        }),
      );
      yield put(saveEmailPreferencesRequestError(saveEmailPreferencesResponse));

    }

    yield put(
      setLoadingState({
        id: 'saveEmailPreferencesLoading',
        isLoading: false,
      }),
    );

  } catch (thrownError) {
    // console.log('saveEmailPreferencesPostGenerator', thrownError);
    // We got a 200 response that was valid JSON, but the expected data type
    // was not returned from the server so we pass a custom error out with our
    // failure action
    console.log('saveEmailPreferencesPostGenerator Error - ', thrownError);
    yield put(saveEmailPreferencesRequestError(thrownError));
    yield put(
      setLoadingState({
        id: 'saveEmailPreferencesLoading',
        isLoading: false,
      }),
    );
  }
}

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* getEmailPreferencesPostGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());\

  yield put(
    setLoadingState({
      id: 'getEmailPreferencesLoading',
      isLoading: true,
    }),
  );
  try {
    const getEmailPreferencesResponse = yield call(
      getEmailPreferences,
      action.payload,
    );

    if (!isEmpty(getEmailPreferencesResponse) && isArray(getEmailPreferencesResponse)) {

      yield put(getEmailPreferencesRequestSuccess(getEmailPreferencesResponse));
    
    } else {

       yield put(getEmailPreferencesRequestSuccess([
        {
          optOutLevelCode: 3,
          verbiage: "Unsubscribe from all emails"
        }
      ]));
    }

    yield put(
      setLoadingState({
        id: 'getEmailPreferencesLoading',
        isLoading: false,
      }),
    );

  } catch (thrownError) {
    // console.log('getEmailPreferencesPostGenerator', thrownError);
    // We got a 200 response that was valid JSON, but the expected data type
    // was not returned from the server so we pass a custom error out with our
    // failure action
    console.log('getEmailPreferencesPostGenerator Error - ', thrownError);
    yield put(getEmailPreferencesRequestError(thrownError));
    yield put(
      setLoadingState({
        id: 'getEmailPreferencesLoading',
        isLoading: false,
      }),
    );
  }
}


function* emailPreferencesListSaga() {
  yield takeLatest(
    SAVE_EMAIL_PREFERENCES_REQUEST_TRIGGER,
    saveEmailPreferencesPostGenerator,
  );
  yield takeLatest(
    GET_EMAIL_PREFERENCES_REQUEST_TRIGGER,
    getEmailPreferencesPostGenerator,
  );
}

export default emailPreferencesListSaga;
