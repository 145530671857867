import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import AppHeaderComponent from '../../components/AppHeaderComponent';
import { triggerGetAppLogoHeaderRequest } from '../../../redux/api/logo/actions';
import { triggerGetEmailPreferencesRequest } from '../../../redux/api/EmailPreferences/actions';

const AppHeader = () => {

  const appLogoHeadersState = useSelector((state) => state.getAppLogoHeaderState);

  const awsSecretState = useSelector((state) => state.awsSecretsState.successPostingAwsSecrets);

  const {
    li, ri, p, cn, API_PASSWORD
  } = awsSecretState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (p && cn) {
      dispatch(triggerGetAppLogoHeaderRequest({
        li, p, cn, API_PASSWORD
      }));
      dispatch(triggerGetEmailPreferencesRequest({
        ri, p, cn, API_PASSWORD
      }));
    }
  }, [p]);

  const isSuccessResponse = !isEmpty(appLogoHeadersState.successGettingLogoAppHeader);

  return (
    <>
      {' '}
      {isSuccessResponse && <AppHeaderComponent idLogo={appLogoHeadersState.successGettingLogoAppHeader} />}
      {' '}
    </>
  );
};

export default AppHeader;
