import React from 'react';
import {
  BrowserRouter, Route, Routes, useLocation
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GlobalStyles from '../shared/styles/globalStyles';
import AppHeader from '../containers/AppHeader';
import EmailPreferences from '../containers/EmailPreferences';
import { triggerAWSSecretRequest } from '../../redux/api/awsSecrets/actions';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/emails/unsubscribe" element={<Home />} />
        <Route path="/" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home() {
  console.log(process.env);

  const { search } = useLocation();
  const param = search.substring(3);

  const salt = param.substring(0, 16);
  const data = param.substring(16);

  const dispatch = useDispatch();

  dispatch(triggerAWSSecretRequest({ salt, data }));

  return (
    <div id="App" className="App">
      <GlobalStyles />
      <AppHeader />
      <EmailPreferences />
    </div>
  );

}

function Error() {

  return (
    <div id="App" className="App">
      <GlobalStyles />
      <center><h1>Page Not Found</h1></center>
    </div>
  );

}

export default AppRoutes;
