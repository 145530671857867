import Api from '../index';
import { API_BASE_PATH } from '../constants';

export const saveEmailPreferences = async ({ ri = '', emailPreferenceOption = '', API_PASSWORD = '' }) => {
  try {
    const api = new Api(API_PASSWORD);
    const baseUrl = `${API_BASE_PATH}/unsubscribe?ri=${ri}&l=${emailPreferenceOption}`;
    const saveEmailPreferencesResponse = await api.put(baseUrl);
    if (saveEmailPreferencesResponse.error) {
      throw saveEmailPreferencesResponse.error;
    }
    return saveEmailPreferencesResponse;
  } catch (error) {
    console.log('Error - saveEmailPreferences', error);
    throw error;
  }
};

export const getEmailPreferences = async ({ p = '', cn = '', API_PASSWORD = '' }) => {
  try {
    const api = new Api(API_PASSWORD);
    const baseUrl = `${API_BASE_PATH}/platform/${p}/client/${cn}/unsubscribe/config`;
    const getEmailPreferencesResponse = await api.get(baseUrl);
    if (getEmailPreferencesResponse.error) {
      throw getEmailPreferencesResponse.error;
    }
    return getEmailPreferencesResponse;
  } catch (error) {
    console.log('Error - getEmailPreferences', error);
    throw error;
  }
};

const exportedFunctions = {
  saveEmailPreferences,
  getEmailPreferences,
};

export default exportedFunctions;
