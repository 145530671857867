import { put, call, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { getAWSSecrets } from './network';
import {
  triggerAWSSecretRequestSuccess,
  triggerAWSSecretRequestError,
} from './actions';
import { setLoadingState } from '../../app/loadingState/actions';
import {
  GET_AWS_SECRETS_REQUEST_TRIGGER,
} from './constants';
import { decryptURL, decryptSecret } from '../../../node/utils';

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* awsSecretsPostGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());\
  yield put(
    setLoadingState({
      id: 'awsSecretsResponseLoading',
      isLoading: true,
    }),
  );
  try {
    const awsSecretsResponse = yield call(
      getAWSSecrets,
      action.payload,
    );

    // If we got what we expect, dispatch our success action
    if (!isEmpty(awsSecretsResponse)) {

      const { salt, data } = action.payload;
      const decryptedSecret = JSON.parse(decryptSecret(awsSecretsResponse));
      const { AES_KEY } = decryptedSecret;
      const finalValue = salt && data ? decryptURL(salt, data, AES_KEY) : null;
      const {
        p, cn, ri, li, vo = '', c = ''
      } = queryString.parse(finalValue);

      yield put(triggerAWSSecretRequestSuccess({
        ...decryptedSecret, ...action.payload, p, cn, ri, li, vo, c
      }));
      yield put(
        setLoadingState({
          id: 'awsSecretsResponseLoading',
          isLoading: false,
        }),
      );
    }
  } catch (thrownError) {
    // console.log('saveEmailPreferencesPostGenerator', thrownError);
    // We got a 200 response that was valid JSON, but the expected data type
    // was not returned from the server so we pass a custom error out with our
    // failure action
    console.log('awsSecretsResponsePostGenerator Error - ', thrownError);
    yield put(triggerAWSSecretRequestError(thrownError));
    yield put(
      setLoadingState({
        id: 'awsSecretsResponseLoading',
        isLoading: false,
      }),
    );
  }
}

function* awsSecretsSaga() {
  yield takeLatest(
    GET_AWS_SECRETS_REQUEST_TRIGGER,
    awsSecretsPostGenerator,
  );
}

export default awsSecretsSaga;
