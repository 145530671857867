const crypto = require('crypto');
global.Buffer = global.Buffer || require('buffer').Buffer;

const encryptSecret = (text) => {

  const algorithm = 'aes-256-ctr';

  const hash = crypto.randomBytes(16).toString('hex');

  const iv = crypto.randomBytes(16);

  const cipher = crypto.createCipheriv(algorithm, hash, iv);

  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

  return {
    iv: iv.toString('hex'),
    content: encrypted.toString('hex'),
    hash
  };
};

const decryptSecret = (response) => {

  const algorithm = 'aes-256-ctr';

  const decipher = crypto.createDecipheriv(algorithm, response.hash, Buffer.from(response.iv, 'hex'));

  const decrpyted = Buffer.concat([decipher.update(Buffer.from(response.content, 'hex')), decipher.final()]);

  return decrpyted.toString();
};

const decryptURL = (salt, data, AES_KEY) => {


  const kValueFromURL = salt + data;

  const decodedValue = Buffer.from(kValueFromURL, 'base64').toString('ascii');

  console.log("kValueFromURL:", kValueFromURL, "decodedValue:", decodedValue);

  return decodedValue;


};


const decryptURLUsingSHA256 = (salt, data, AES_KEY) => {

  /* Value of parameter K and substring starting from position 17 till end of string
  http://localhost:3000/emails/unsubscribe?k=FEIACEFLIXEJPSS101t+nAgRHhPzKLpYkyY613pa5filb7XK6RFERKXVCesvKtgFOFBbBesOSoJX5k+e
  salt = Value of parameter K and substring starting from 0 till 16 (first 16 characters) - FEIACEFLIXEJPSS1
  data = Value of parameter K and substring starting from position 17 till end of string - 01t+nAgRHhPzKLpYkyY613pa5filb7XK6RFERKXVCesvKtgFOFBbBesOSoJX5k+e
  password - password to be stored and fetched from aws secret manager. it will be unique across different environments
*/

  const algorithm = 'aes-256-cbc';

  // password to be stored and fetched from aws secret manager
  // it will be unique across environments

  const password = AES_KEY;
  const digest = 'sha256';
  const key = crypto.pbkdf2Sync(password, salt, 65536, 32, digest);
  const iv = Buffer.from(salt);
  const decipher = crypto.createDecipheriv(algorithm, key, iv);
  let decrypted = decipher.update(data, 'base64');
  decrypted += decipher.final();
  return decrypted;

};

module.exports = {
  encryptSecret,
  decryptSecret,
  decryptURL
};
